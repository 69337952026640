const Status = [
    {
        name: "Enable",
        vals: "1",
    },
    {
        name: "Disable",
        vals: "0",
    },
];

export default Status;