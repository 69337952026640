import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Fade from 'react-reveal/Fade';
import { toast } from 'react-toastify';
import ReactLoading from "react-loading";


import Shape from "../../theme_web/assets/img/shape.png";
import Location from "../../theme_web/assets/img/location.png";
import Email from "../../theme_web/assets/img/email.png";
import Phone from "../../theme_web/assets/img/phone.png";



export default function Home() {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);

  const [banner, setBanner] = useState([]);
  const [about, setAbout] = useState([]);
  const [services, setServices] = useState([]);
  const [clients1, setClients1] = useState([]);
  const [clients2, setClients2] = useState([]);
  const [clients3, setClients3] = useState([]);
  const [products, setProducts] = useState([]);
  const [settings, setSettings] = useState([]);

  // const [oldSlide, setOldSlide] = useState(0);
  // const [activeSlide, setActiveSlide] = useState(0);
  // const [activeSlide2, setActiveSlide2] = useState(0);
  const [activeCategory1, setActiveCategory1] = useState('');
  const [activeCategory2, setActiveCategory2] = useState('');
  const [activeCategory3, setActiveCategory3] = useState('');



  function changeCurrentCategory1(index) {
    // let filtered = clients.filter((client, key) => key === index)
    // console.log(clients[0].cat_name);
    setActiveCategory1(clients1[index].cat_name);
    // console.log(clients);
  }
  function changeCurrentCategory2(index) {
    // let filtered = clients.filter((client, key) => key === index)
    // console.log(clients[0].cat_name);
    setActiveCategory2(clients2[index].cat_name);
    // console.log(clients);
  }
  function changeCurrentCategory3(index) {
    // let filtered = clients.filter((client, key) => key === index)
    // console.log(clients[0].cat_name);
    setActiveCategory3(clients3[index].cat_name);
    // console.log(clients);
  }

  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  })

  function handleChange(e) {
    setContactForm({
      ...contactForm,
      [e.target.name]: e.target.value
    })

  }

  function handleContactForm(e) {
    e.preventDefault();
    fetch(`https://nyatapol.com/backend/web_api/home/contact_form`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(contactForm),
    })
      .then(resp => resp.json())
      .then(response => {
        // alert('success');
        // handleBlogUpdate(updatedItem.data)
        if (response.status == "success") {
          toast.success(response.status_message);
        } else {
          toast.error(response.status_message);
        }
        window.location.reload(false);
      })
  }

  let slick1 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    beforeChange: (current, next) => {
      // setOldSlide(current);
      // setActiveSlide(next);
    },
    afterChange: current => {
      // setActiveSlide2(current);
      // console.log(clients);
      changeCurrentCategory1(current);
    },
    // this.setState({ activeSlide2: current }),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  }

  let slick2 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    beforeChange: (current, next) => {
      // setOldSlide(current);
      // setActiveSlide(next);
    },
    afterChange: current => {
      // setActiveSlide2(current);
      // console.log(clients);
      changeCurrentCategory2(current);
    },
    // this.setState({ activeSlide2: current }),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  }

  let slick3 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    beforeChange: (current, next) => {
      // setOldSlide(current);
      // setActiveSlide(next);
    },
    afterChange: current => {
      // setActiveSlide2(current);
      // console.log(clients);
      changeCurrentCategory3(current);
    },
    // this.setState({ activeSlide2: current }),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  }

  useEffect(() => {

    fetch(`https://nyatapol.com/backend/web_api/home`, {

    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.status == "success") {
            setBanner(result.banner);
            setAbout(result.about_us);
            setServices(result.services);
            setClients1(result.clients1);
            setClients2(result.clients2);
            setClients3(result.clients3);
            setProducts(result.products);
            setSettings(result.site_settings);
            setIsLoaded(false);
          } else {
            // alert(result.status_message);
            setError(result.status_message);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setError(error);
        }
      )
  }, [])

  return (
    <div className="home_main">
      <main className="l-main">
        {
          isLoaded ? (<div className="loading_front"><ReactLoading
            type="spinningBubbles"
            color="#F10603"
            height={100}
            width={50}
          /></div>) : (
            <div className="bdy">
              <Fade top>
                {/* <!--========== HOME ==========--> */}
                <section className="home" id="home">
                  <div className="home__container">
                    <img src={banner.featured_img} alt="" className="home__img" />
                    <Fade left cascade>
                      <div className="home__data">
                        <h1 className="home__title"><span>{banner.title}:</span><br />
                          {banner.subtitle}</h1>
                        <h2 className="home__subtitle" dangerouslySetInnerHTML={{ __html: banner.description }}></h2>
                        {/* <!-- <button className="home__button">Start Your Project</a> --> */}
                      </div>
                    </Fade>
                  </div>
                </section>
              </Fade>


              {/* <!--========== ABOUT ==========--> */}
              <section className="about section bd-container" id="about">
                <div className="about__container  bd-grid">
                  <Fade right>
                    <div className="about__data">
                      {/* <!-- <span className="section-subtitle about__initial">About us</span> --> */}
                      <h2 className="section-title about__initial">{about.title}</h2>
                      <p className="about__description" dangerouslySetInnerHTML={{ __html: about.description }}>

                      </p>
                      {/* <!-- <a href="#" className="button">Read More</a> --> */}
                    </div>
                  </Fade>
                  <Fade left>
                    <img src={about.featured_image} alt="" className="about__img" />
                  </Fade>

                </div>
              </section>

              {/* <!--========== SERVICES ==========--> */}
              <section className="services section bd-container" id="services">
                <span className="section-subtitle">SERVICES</span>
                <h2 className="section-title">Our Services</h2>

                <div className="services__container  bd-grid">{
                  (services.length > 0) ?
                    (services.map((val, key) => (

                      <div className="services__content" key={key}>
                        <img src={val.featured_img} className="services__img" />
                        <h3 className="services__title">{val.title}</h3>
                        <p className="services__description" dangerouslySetInnerHTML={{ __html: val.description }}>

                        </p>
                      </div>

                    ))) : (<></>)}
                </div>
              </section>

              {/* <!--========== Clients ==========--> */}
              <section className="clients section" id="clients">
                <span className="section-subtitle">CLIENTS</span>
                <h2 className="section-title">Our Clients</h2>
                {/* <!--========== Clients with slick slider ==========--> */}
                <div className="allSliders">
                  <div className="container1">
                    {/* <!-- <h1>Our Popular Brand</h1> --> */}
                    <p className="section-title cat_title">
                      {activeCategory1}
                    </p>
                    <Slider {...slick1}>
                      {
                        (clients1.length > 0) ?
                          (clients1.map((client, keyc) => (
                            <div className="item" key={keyc}>
                              <a><img src={client.featured_img} /></a>
                            </div>
                          ))) : (<></>)
                      }
                    </Slider>
                  </div>
                  <div className="container1">
                    {/* <!-- <h1>Our Popular Brand</h1> --> */}
                    <p className="section-title cat_title">
                      {activeCategory2}
                    </p>
                    <Slider {...slick2}>
                      {
                        (clients2.length > 0) ?
                          (clients2.map((client, keyc) => (
                            <div className="item" key={keyc}>
                              <a><img src={client.featured_img} /></a>
                            </div>
                          ))) : (<></>)
                      }
                    </Slider>
                  </div>
                  <div className="container1">
                    {/* <!-- <h1>Our Popular Brand</h1> --> */}
                    <p className="section-title cat_title">
                      {activeCategory3}
                    </p>
                    <Slider {...slick3}>
                      {
                        (clients3.length > 0) ?
                          (clients3.map((client, keyc) => (
                            <div className="item" key={keyc}>
                              <a><img src={client.featured_img} /></a>
                            </div>
                          ))) : (<></>)
                      }
                    </Slider>
                  </div>
                </div>
              </section>
              {/* <!--========== Products ==========--> */}
              <section className="products section bd-container" id="products">
                <span className="section-subtitle">PRODUCTS</span>
                <h2 className="section-title client-slider-header">Our Field Of Expertise</h2>
                <div className="products__container  bd-grid">
                  {
                    (products.length > 0) ?
                      (products.map((product, keyc) => (
                        <div className="products__content">
                          <img src={product.featured_img} href="Project Ma  nagement" className="products__img" />
                          <h3 className="products__title">{product.title}</h3>
                        </div>
                      ))) : (<></>)
                  }


                </div>

              </section>
              {/* <!--========== Contact Us ==========--> */}
              <section className="contact section bd-container" id="contact">
                <div className="container">
                  <span className="big-circle"></span>
                  <img src={Shape} className="square" alt="" />
                  <div className="form">
                    <div className="contact-info">
                      <h3 className="title">Contact Us</h3>
                      {/* <!-- <p className="text">
                  Fill up the Form and our team will get back to within 24 hrs
                </p> --> */}

                      <div className="info">
                        <div className="information">
                          <img src={Location} className="icon" alt="" />
                          <p>{settings.address}</p>
                        </div>
                        <div className="information">
                          <img src={Email} className="icon" alt="" />
                          <p>{settings.email}</p>
                        </div>
                        <div className="information">
                          <img src={Phone} className="icon" alt="" />
                          <p>{settings.telephone}</p>
                        </div>
                      </div>

                      <div className="map-box" dangerouslySetInnerHTML={{ __html: settings.map_location }}>

                      </div>

                    </div>

                    <div className="contact-form">
                      <span className="circle one"></span>
                      <span className="circle two"></span>

                      <form action="" method="post" autoComplete="off">
                        <h3 className="title">Message us</h3>
                        <div className="input-container">
                          <input type="text" name="name" className="input" placeholder="Full Name" onChange={handleChange} />
                          {/* <label >Username</label> */}
                          {/* <span>Username</span> */}
                        </div>
                        <div className="input-container">
                          <input type="email" name="email" className="input" placeholder="Email" onChange={handleChange} />
                          {/* <label >Email</label>
                          <span>Email</span> */}
                        </div>
                        <div className="input-container">
                          <input type="tel" name="phone" className="input" placeholder="Phone" onChange={handleChange} />
                          {/* <label >Phone</label>
                          <span>Phone</span> */}
                        </div>
                        <div className="input-container textarea">
                          <textarea name="message" className="input" placeholder="Your Message" onChange={handleChange}></textarea>
                          {/* <label >Message</label>
                          <span>Message</span> */}
                        </div>
                        <input type="submit" name="submit" value="Submit" className="btn" onClick={handleContactForm} />

                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )
        }

      </main>
    </div>
  );
}