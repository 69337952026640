import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Button } from 'reactstrap';


let but = {
    color: "#fff",
    fontWeight: "bold",
    background: "#6c757d",
};

let imgprev = {
    width: "100%",
    height: "75px",
    objectFit: "contain",
}

export default function List() {

    const navigate = useNavigate();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    const [activePage, setActivePage] = useState(1);
    const [totalRow, setTotalRow] = useState(null);

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect(() => {
        //   var my_script4 = new_script('/theme_admin/costume/js/main.js'); 

        fetch(`https://nyatapol.com/backend/api/subscriber/all/${activePage}`, {

        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status == "Success") {
                        setIsLoaded(true);
                        setTotalRow(parseInt(result.total));
                        setItems(result.items);
                    } else {
                        // alert(result.status_message);
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    // soft delete
    function handelDelete(id) {

        fetch(`https://nyatapol.com/backend/api/subscriber/soft_delete/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(resp => resp.json())
            .then(resp => {
                if (resp.status == "Success") {
                    // alert(resp.status_message); 
                    // onDeleteItem(resp.data);
                    // navigate('/admin/item');
                    window.location.reload(false);
                } else {
                    // alert(resp.status_message);  
                }
            })
    }

    function handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        setActivePage(pageNumber);
        fetch(`https://nyatapol.com/backend/api/subscriber/all/${pageNumber}`)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status == "Success") {
                        setTotalRow(parseInt(result.total));
                        setItems(result.items);
                    } else {
                        // alert(result.status_message);
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }

    return (
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <p>All Subscribers</p>
                            </div>
                            <div className="card-body">
                                <table id="example1" className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Email</th>
                                            <th>Created</th>
                                            <th>Updated</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (items.length > 0) ?
                                                (items.map((val, key) => (
                                                    <tr key={val.id.toString()}>
                                                        <td>{key + 1}</td>
                                                        <td>{val.email}</td>
                                                        <td>{val.created}</td>
                                                        <td>{val.updated}</td>
                                                        <td>
                                                            <Button onClick={() => {
                                                                handelDelete(val.id);
                                                            }}>
                                                                <i className="far fa-trash-alt nav-icon"></i>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))) : (<tr><td colSpan="5">No Records Found !!!</td></tr>)

                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Email</th>
                                            <th>Created</th>
                                            <th>Updated</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                </table>
                                {(items.length > 0) ? (
                                    <div>
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={6}
                                            totalItemsCount={totalRow}
                                            pageRangeDisplayed={3}
                                            onChange={handlePageChange.bind(this)}
                                        />
                                    </div>) : (<></>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='scpt'>

            </div>
        </section>
    );
} 