import React from 'react';
import FieldComponent from "../../../components/FieldComponent";

import { Editor } from "@tinymce/tinymce-react";

// exported as deafult
import Status from "../../../components/values/Status";

let imgprev = {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    margin: "20px",
}
function Edit({ editForm, handleChange, handleChangeTextarea, handleItemUpdate, cancelEdit, handleImageChange }) {
    let { id, title, description, subtitle, featured_img, featured_img_data, rank, status } = editForm

    // PATCH request; calls handleItemUpdate to push changes to the page
    function handleEditForm(e) {
        // console.log(editForm);return false;
        e.preventDefault();
        fetch(`https://nyatapol.com/backend/api/service/edit/${id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(editForm),
        })
            .then(resp => resp.json())
            .then(updatedItem => {
                console.log(updatedItem.data);
                handleItemUpdate(updatedItem.data)
            })
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Edit Blog</h3>
                        </div>
                        <div className="card-body">
                            <form id="quickForm">
                                <div className="card-body">
                                    <FieldComponent
                                        name='title'
                                        type='text'
                                        fieldType='input'
                                        label='Title'
                                        handleChange={handleChange}
                                        values={title}
                                    />
                                    <FieldComponent
                                        name='subtitle'
                                        type='text'
                                        fieldType='input'
                                        label='Subtitle'
                                        handleChange={handleChange}
                                        values={subtitle}
                                    />
                                    {/* <FieldComponent
                                        name='description'
                                        type='textarea'
                                        fieldType='textarea'
                                        label='Description'
                                        handleChange={handleChange}
                                        values={description}
                                    /> */}
                                    <div className="form-group">
                                        <label>Description</label>
                                        <Editor
                                            value={description}
                                            init={{
                                                height: 500,
                                                menubar: true
                                            }}
                                            onEditorChange={handleChangeTextarea}
                                        />
                                    </div>
                                    <FieldComponent
                                        name='featured_img'
                                        type='file'
                                        fieldType='input'
                                        label='Featured Image'
                                        handleChange={handleImageChange}
                                    />
                                    {
                                        featured_img ? <img src={featured_img} alt='' style={imgprev} /> : <></>
                                    }
                                    <FieldComponent
                                        name='rank'
                                        type='number'
                                        fieldType='input'
                                        label='Rank'
                                        handleChange={handleChange}
                                        values={rank}
                                    />
                                    <FieldComponent
                                        name='status'
                                        type='select'
                                        fieldType='select'
                                        label='Status'
                                        handleChange={handleChange}
                                        values={Status}
                                        selected={status}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary" onClick={handleEditForm}>Submit</button>
                            <button className="btn btn-primary" onClick={cancelEdit}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Edit