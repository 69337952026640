import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export default function FieldComponent({ name, type, fieldType, label, handleChange, values, selected, isrequired }) {
    // console.log(isrequired);
    const Switch = (val) => {
        // console.log(val);
        switch (val.fieldType) {
            case "input":
                return <input type={val.type} name={val.name} className="form-control" id={val.name} placeholder={"Enter " + val.label} onChange={val.handleChange} value={val.values} />;
            case "textarea":
                return <textarea
                    name={val.name}
                    className="form-control" id={val.name} placeholder={val.label}
                    onChange={val.handleChange}
                    value={val.values}
                ></textarea>;
            case "select":
                return <select name={val.name} id={val.name} className="form-control" onChange={val.handleChange} value={val.selected}>
                    <option>Select</option>
                    {
                        val.values.map((data, key) => (
                            <option key={data.vals} value={data.vals} >{data.name}</option>
                        ))
                    }
                </select >
            default:
                return "foo";
        }
    };

    return (
        <div className="form-group">
            <label>{label}{isrequired == 'yes' ? (<span>*</span>) : (<></>)}</label>
            {Switch({ fieldType, type, name, label, values, handleChange, selected })}
        </div>
    );
} 