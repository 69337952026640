export const ContentType = [
    {
        name: "About Us",
        vals: "about",
    },
    {
        name: "Contact",
        vals: "contact",
    },
    {
        name: "Privacy/Policy",
        vals: "policy",
    },
    {
        name: "Terms & Conditions",
        vals: "terms",
    },
    {
        name: "Others",
        vals: "others",
    },
];