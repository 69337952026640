import React from "react";
import { Navigate , Route ,Outlet } from "react-router-dom"; 
import Header from "../view/website/layout/Header";
import Footer from "../view/website/layout/Footer";

function PublicOutlet() {  

    return <div><Header /> <Outlet /> <Footer /></div>
} 

export default PublicOutlet;