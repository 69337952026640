import React from 'react';
import { Link } from 'react-router-dom';  

export default function BlogDetail() {
  return(
    <div className="wrapper">
          <h2>This is Blog Detail Page</h2>
          <h3>In This Section</h3>
          <h4>You can view full detail of blog !!!</h4> 
    </div>
  );
}