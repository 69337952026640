import React from "react";
import { Navigate, Route, Outlet, Routes, useRoutes, useLocation, Link, useParams } from "react-router-dom";
import AddBlog from "./AddBlog";
import Blgs from "./Blgs";

export default function BlogRoute() {

  const { pathname } = useLocation();
  // const { id } = useParams(); 

  // console.log(pathname);return false;

  return (
    <div>
      <Routes>
        <Route path="/" element={<Blogs />}>
          <Route index element={<Blgs />} />
          <Route path="/add" element={<AddBlog />} />
        </Route>
      </Routes>
    </div>
  );

  // let element = useRoutes([
  //   // A route object has the same properties as a <Route>
  //   // element. The `children` is just an array of child routes. 
  //   {
  //     path: '/',
  //     element: <Blogs />,
  //     children: [
  //       { path: '/', element: <Blog /> },
  //       { path: 'add', element: <AddBlog /> }, 
  //     ]
  //   }
  // ]);

  // return element;
}

function Blogs() {
  const { params } = useParams();
  // console.log(params);return false;
  return (
    <div className="blogadmin">
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Blogs</h1>
              </div>
              {/* <!-- /.col --> */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/admin/dashboard">Home</Link></li>
                  <li className="breadcrumb-item active">Blogs</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            {/* <!-- Info boxes --> */}
            <div className="row">
              <div className="col-12 col-sm-6 col-md-12">
                <Outlet />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}