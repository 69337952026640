import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import useScript from '../../../components/UseScript';
import { Helmet } from "react-helmet";
import "../../../theme_admin/style.css";
import "../../../theme_admin/plugins/fontawesome-free/css/all.min.css";
import "../../../theme_admin/plugins/overlayScrollbars/css/OverlayScrollbars.min.css";
import "../../../theme_admin/dist/css/adminlte.css";
import Logo from "../../../theme_admin/dist/img/logo.png";
import UserPhoto from "../../../theme_admin/dist/img/user1-128x128.jpg";
import UserPhoto8 from "../../../theme_admin/dist/img/user8-128x128.jpg";
import UserPhoto3 from "../../../theme_admin/dist/img/user3-128x128.jpg";
import UserPhoto2 from "../../../theme_admin/dist/img/user2-160x160.jpg";

export default function AdminHeader() {

  const [error, setError] = useState(null);
  const [settings, setSettings] = useState([]);
  const [dynamicLogo, setDynamicLogo] = useState(Logo);
  const [dynamicSiteName, setDynamicSiteName] = useState('Nyatapol Technology');

  function new_script(src) {
    return new Promise(function (resolve, reject) {
      var script = document.createElement('script');
      script.src = src;
      script.addEventListener('load', function () {
        resolve();
      });
      script.addEventListener('error', function (e) {
        reject(e);
      });
      document.getElementById("scptdatatables").appendChild(script);
    })
  };

  const mystyle = {
    background: "#0f8d13",
    padding: "25px",
    color: "#fff",
    fontSize: "20px",
    fontWeight: "bold",
  };

  const handleLogout = (e) => {
    localStorage.clear();
    window.location.pathname = "/signin";
  };

  useEffect(() => {
    // var my_script4 = new_script('/theme_admin/costume/js/main.js');   
    document.body.classList.add('admin_side');
    fetch(`https://nyatapol.com/backend/web_api/home/site_settings`, {

    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.status == "success") {
            setSettings(result.site_settings);
            setDynamicLogo(result.site_settings.logo);
            setDynamicSiteName(result.site_settings.site_name)
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setError(error);
        }
      )
  }, [])

  return (
    <div className="adminhead">
      {/* <Helmet>
        <script src="/theme_admin/dist/js/adminlte.js"></script>
      </Helmet> */}
      <div id='scptdatatables'>

      </div>

      <div className="preloader flex-column justify-content-center align-items-center">
        <img className="animation__wobble" src={dynamicLogo} alt="AdminLTELogo" />
      </div>

      <nav className="main-header navbar navbar-expand navbar-dark">
        {/* <!-- Left navbar links --> */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/" className="nav-link" target="_blank" rel="noopener noreferrer">Website</Link>
          </li>
        </ul>
        {/* 
            <!-- Right navbar links --> */}
        <ul className="navbar-nav ml-auto">
          {/* <!-- Navbar Search --> */}
          <li className="nav-item">
            <a className="nav-link" data-widget="navbar-search" href="#" role="button">
              <i className="fas fa-search"></i>
            </a>
            <div className="navbar-search-block">
              <form className="form-inline">
                <div className="input-group input-group-sm">
                  <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                  <div className="input-group-append">
                    <button className="btn btn-navbar" type="submit">
                      <i className="fas fa-search"></i>
                    </button>
                    <button className="btn btn-navbar" type="button" data-widget="navbar-search">
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>

          <li className="nav-item">
            <a className="nav-link" data-widget="fullscreen" href="#" role="button">
              <i className="fas fa-expand-arrows-alt"></i>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-widget="fullscreen" onClick={handleLogout} role="button">
              <i className="fas fa-sign-out-alt"></i>
            </a>
          </li>
        </ul>
      </nav>

      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* <!-- Brand Logo --> */}
        <a className="brand-link">
          <img src={dynamicLogo} alt="AdminLTE Logo" className="brand-image img-circle elevation-3" />
          <span className="brand-text font-weight-light">{dynamicSiteName}</span>
        </a>

        {/* <!-- Sidebar --> */}
        <div className="sidebar">
          {/* <!-- Sidebar user panel (optional) --> */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">

          </div>

          {/* <!-- SidebarSearch Form --> */}
          <div className="form-inline">
            <div className="input-group" data-widget="sidebar-search">
              <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
              <div className="input-group-append">
                <button className="btn btn-sidebar">
                  <i className="fas fa-search fa-fw"></i>
                </button>
              </div>
            </div>
          </div>

          {/* <!-- Sidebar Menu --> */}
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              {/* <!-- Add icons to the links using the .nav-icon className
                      with font-awesome or any other icon font library --> */}
              <li className="nav-item">
                <Link to="/admin/dashboard" className="nav-link"><p>
                  <i className="nav-icon fas fa-tachometer-alt fa-spin"></i>
                  Dashboard<span className="right badge badge-danger">home</span>
                </p></Link>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-copy"></i>
                  <p>
                    Blogs
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/admin/blog/" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>List</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/blog/add" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add New</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-book"></i>
                  <p>
                    Contents
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/admin/content/" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>List</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/content/add" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add New</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-sliders-h"></i>
                  <p>
                    Slider
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/admin/slider/" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>List</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/slider/add" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add New</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-copy"></i>
                  <p>
                    Items
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/admin/item/" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>List</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/item/add" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add New</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-header">Services & Portfolio</li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-tasks"></i>
                  <p>
                    Services
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/admin/service/" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>List</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/service/add" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add New</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-briefcase"></i>
                  <p>
                    Portfolio
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/admin/portfolio/" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>List</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/portfolio/add" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add New</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-header">User Management</li>
              {/* <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-address-card"></i>
                  <p>
                    User Role
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/admin/user_role/" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>List</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/user_role/add" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add New</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-user"></i>
                  <p>
                    User
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/admin/user/" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>List</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/user/add" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add New</p>
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-user-tie"></i>
                  <p>
                    Client Category
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/admin/client_category/" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>List</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/client_category/add" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add New</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-user-tie"></i>
                  <p>
                    Clients
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/admin/clients/" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>List</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/clients/add" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add New</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-user-tie"></i>
                  <p>
                    Team
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/admin/team/" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>List</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/team/add" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add New</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-header">Site Settings</li>
              <li className="nav-item">
                <Link to="/admin/site_settings" className="nav-link"><p>
                  <i className="nav-icon fas fa-cog"></i>
                  Site Settings
                </p></Link>
              </li>
              <li className="nav-header">Site Users</li>
              <li className="nav-item">
                <Link to="/admin/subscriber" className="nav-link"><p>
                  <i className="nav-icon fas fa-rocket"></i>
                  Subscribers
                </p></Link>
              </li>
              <li className="nav-item">
                <Link to="/admin/feedback" className="nav-link"><p>
                  <i className="nav-icon fas fa-envelope"></i>
                  Feedback Message
                </p></Link>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-comments"></i>
                  <p>
                    Testimonials
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/admin/testimonials/" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>List</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/testimonials/add" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add New</p>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          {/* <!-- /.sidebar-menu --> */}
        </div>
        {/* <!-- /.sidebar --> */}
      </aside>

    </div>
  );
}