import React from 'react';
import FieldComponent from "../../../components/FieldComponent";

// exported as deafult
import Status from "../../../components/values/Status";

let imgprev = {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    margin: "20px",
}
function Edit({ editForm, handleChange, handleItemUpdate, cancelEdit, handleImageChange, categories }) {
    let { id, name, description, address, contact, featured_img, featured_img_data, cat_id, status } = editForm

    // PATCH request; calls handleItemUpdate to push changes to the page
    function handleEditForm(e) {
        // console.log(editForm);return false;
        e.preventDefault();
        fetch(`https://nyatapol.com/backend/api/clients/edit/${id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(editForm),
        })
            .then(resp => resp.json())
            .then(updatedItem => {
                console.log(updatedItem.data);
                handleItemUpdate(updatedItem.data)
            })
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Edit Client</h3>
                        </div>
                        <div className="card-body">
                            <form id="quickForm">
                                <div className="card-body">
                                    <FieldComponent
                                        name='name'
                                        type='text'
                                        fieldType='input'
                                        label='Name'
                                        handleChange={handleChange}
                                        values={name}
                                    />
                                    <FieldComponent
                                        name='address'
                                        type='text'
                                        fieldType='input'
                                        label='Address'
                                        handleChange={handleChange}
                                        values={address}
                                    />
                                    <FieldComponent
                                        name='contact'
                                        type='text'
                                        fieldType='input'
                                        label='Contact'
                                        handleChange={handleChange}
                                        values={contact}
                                    />
                                    <FieldComponent
                                        name='description'
                                        type='textarea'
                                        fieldType='textarea'
                                        label='Description'
                                        handleChange={handleChange}
                                        values={description}
                                    />
                                    <FieldComponent
                                        name='featured_img'
                                        type='file'
                                        fieldType='input'
                                        label='Featured Image'
                                        handleChange={handleImageChange}
                                    />
                                    {
                                        featured_img ? <img src={featured_img} alt='' style={imgprev} /> : <></>
                                    }

                                    <FieldComponent
                                        name='cat_id'
                                        type='select'
                                        fieldType='select'
                                        label='Category'
                                        handleChange={handleChange}
                                        values={categories}
                                        selected={cat_id}
                                    />
                                    <FieldComponent
                                        name='status'
                                        type='select'
                                        fieldType='select'
                                        label='Status'
                                        handleChange={handleChange}
                                        values={Status}
                                        selected={status}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary" onClick={handleEditForm}>Submit</button>
                            <button className="btn btn-primary" onClick={cancelEdit}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Edit