import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import CKEditor from "react-ckeditor-component";
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Helmet } from "react-helmet";
import { Button } from 'reactstrap';
import Edit from "./Edit";

let imgprev = {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    margin: "20px",
}

export default function Add() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    // this.uploadSingleFile = this.uploadSingleFile.bind(this)
    // this.upload = this.upload.bind(this)

    const [addForm, setAddForm] = useState({
        id: "",
        title: "",
        description: "",
        subtitle: "",
        featured_img: null,
        featured_img_data: null
    })

    const navigate = useNavigate();

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    function handleAddForm(e) {
        // console.log(addForm);return false;
        e.preventDefault();
        fetch(`https://nyatapol.com/backend/api/item/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(addForm),
        })
            .then(resp => resp.json())
            .then(updatedItem => {
                console.log(updatedItem.status_message);
                // alert('success');
                // handleBlogUpdate(updatedItem.data)
                navigate('/admin/item')
            })
    }

    // capture user input in edit form inputs
    function handleChange(e) {
        // console.log(e.target.value);return false;
        // if (e.target.files[0]) { 
        //     var file = e.target.files[0]; 
        //     var reader = new FileReader();
        //     reader.onload = function(event) {
        //         // The file's text will be printed here
        //         console.log(event.target.result);
        //         setAddForm({
        //                 ...addForm,
        //                 ['featured_img_data']: event.target.result,
        //                 ['featured_img']: e.target.files[0]
        //                 });
        //     };

        //     reader.readAsText(file);

        //     console.log(addForm);
        // }else{
        //     setAddForm({
        //         ...addForm,
        //         [e.target.name]: e.target.value
        //         })
        // }
        // if(e.editor.getData()){
        //     var newContent = e.editor.getData();
        //     setAddForm({
        //         ...addForm,
        //         ['description']: newContent
        //         }) 
        // }else{
        //     setAddForm({
        //         ...addForm,
        //         [e.target.name]: e.target.value
        //         }) 
        // }
        setAddForm({
            ...addForm,
            [e.target.name]: e.target.value
        })

    }

    // function handleOnChangeTextarea(evt){
    //     var newContent = evt.editor.getData();
    //     setAddForm({
    //         ...addForm,
    //         ['description']: newContent
    //         }) 
    //     console.log("onChange fired with event info: ", newContent);
    // }

    function handleImageChange(e) {
        if (e.target.files[0]) {
            // console.log("Featured Image: ", URL.createObjectURL(e.target.files[0])); 

            // const reader = new FileReader();
            // reader.addEventListener("load", () => {
            // //   setImgData(reader.result);
            //   setAddForm({
            //     ...addForm,
            //     ['featured_img_data']: reader.result,
            //     ['featured_img']: e.target.files[0]
            //     });
            // });
            // reader.readAsDataURL(e.target.files[0]);
            var file = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function (event) {
                // The file's text will be printed here
                console.log(event.target.result);
                setAddForm({
                    ...addForm,
                    ['featured_img_data']: event.target.result,
                    ['featured_img']: URL.createObjectURL(e.target.files[0])
                });
            };

            reader.readAsDataURL(file);
        }
    }


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card card-primary">
                        <form id="quickForm">
                            <div className="card-header">
                                <h3 className="card-title">Add Blog</h3>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <label for="title">Title</label>
                                    <input type="text" name="title" className="form-control" id="title" placeholder="Enter Title" onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label for="description">Description</label>
                                    {/* <input type="text" name="description" className="form-control" id="description" placeholder="Description"  onChange={handleChange}/> */}
                                    {/* <CKEditor
                                    // editor={ ClassicEditor }
                                    data={"<p>Hello from CKEditor 5!</p>"}
                                     
                                    
                                /> */}
                                    <textarea
                                        name='description'
                                        className="form-control" id="description" placeholder="Description"
                                        onChange={handleChange}
                                    ></textarea>
                                    {/* <CKEditor 
                                    activeClass="p10" 
                                    content={'rajesh'} 
                                    events={{
                                        // "blur": this.onBlur,
                                        // "afterPaste": this.afterPaste,
                                        "onChange": handleChange
                                    }}
                                /> */}
                                </div>
                                <div className="form-group">
                                    <label for="subtitle">Sub title</label>
                                    <input type="text" name="subtitle" className="form-control" id="subtitle" placeholder="Subtitle" onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label for="featured_img">Featured Image</label>
                                    <input type="file" name="featured_img" className="form-control" id="featured_img" placeholder="Featured Image" onChange={handleImageChange} />
                                    {
                                        addForm.featured_img ? <img src={addForm.featured_img} alt='' style={imgprev} /> : <></>
                                    }
                                </div>
                            </div>
                            <div className="card-footer">
                                <button type="submit" className="btn btn-primary" onClick={handleAddForm}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
} 