import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// Importing toastify module
import { toast } from 'react-toastify';

// Import toastify css file
// import 'react-toastify/dist/ReactToastify.css';

//  // toast-configuration method,
//  // it is compulsory method.
// toast.configure(); 

let imgprev = {
  width: "150px",
  height: "150px",
  objectFit: "contain",
  margin: "20px",
}

export default function Site() {

  const [error, setError] = useState(null);
  const [addForm, setAddForm] = useState({
    id: "",
    site_name: "",
    site_slogan: "",
    web_url: "",
    address: "",
    mobile: "",
    telephone: "",
    map_location: "",
    contact_detail: "",
    email: "",
    facebook: "",
    whatsapp: "",
    skype: "",
    twitter: "",
    instagram: "",
    youtube: "",
    googleplus: "",
    linked_in: "",
    meta_title: "",
    meta_description: "",
    meta_key_words: "",
    logo: null,
    logo_local_url: null,
    fav: null,
    fav_local_url: null
  })

  function handleChange(e) {
    setAddForm({
      ...addForm,
      [e.target.name]: e.target.value
    })

  }

  useEffect(() => {

    fetch(`https://nyatapol.com/backend/api/site_settings/data_for_form`, {

    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.status == "Success") {
            // console.log(result.data);return false;
            setAddForm(result.data);
          } else {
            // alert(result.status_message);
          }
        },
        (error) => {
          setError(error);
        }
      )
  }, [])

  function handleAddForm(e) {
    e.preventDefault();
    // console.log(addForm);return false;

    fetch(`https://nyatapol.com/backend/api/site_settings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(addForm),
    })
      .then(resp => resp.json())
      .then(result => {
        console.log(result.status_message);
        //   const notify = ()=>{
        //     // inbuilt-notification
        //     // toast.warning('Danger')
        //     // inbuilt-notification
        //     toast.success('succsess')
        //     // inbuilt-notification
        //     // toast.info('GeeksForGeeks')
        //     // inbuilt-notification
        //     // toast.error('Runtime error')
        //     // default notification
        //     // toast('Hello Geeks')

        // }
        if (result.status == "Success") {
          toast.success(result.status_message);
        } else {
          toast.error(result.status_message);
        }
      })
  }

  function handleImageChange(e) {
    if (e.target.files[0]) {
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = function (event) {
        // The file's text will be printed here
        console.log(event.target.result);
        setAddForm({
          ...addForm,
          ['logo']: event.target.result,
          ['logo_local_url']: URL.createObjectURL(e.target.files[0])
        });
      };

      reader.readAsDataURL(file);
    }
  }

  function handleFavChange(e) {
    if (e.target.files[0]) {
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = function (event) {
        // The file's text will be printed here
        console.log(event.target.result);
        setAddForm({
          ...addForm,
          ['fav']: event.target.result,
          ['fav_local_url']: URL.createObjectURL(e.target.files[0])
        });
      };

      reader.readAsDataURL(file);
    }
  }

  return (
    <div className="blogadmin">
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Site Settings Module</h1>
              </div>
              {/* <!-- /.col --> */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/admin/dashboard">Home</Link></li>
                  <li className="breadcrumb-item active">Site Settings</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            {/* <!-- Info boxes --> */}
            <div className="row">
              <div className="col-12 col-sm-6 col-md-12">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div className="card card-primary">
                        <form id="quickForm">
                          <div className="card-header">
                            <h3 className="card-title">Site Settings</h3>
                          </div>
                          <div className="card-body">
                            <div className='row'>
                              <div className='col-md-4 col-sm-4'>
                                <div className="form-group">
                                  <label for="site_name">Site Name</label>
                                  <input type="text" name="site_name" className="form-control" id="site_name" placeholder="Enter Site Name" value={addForm.site_name ? addForm.site_name : ''} onChange={handleChange} />
                                </div>
                              </div>
                              <div className='col-md-4 col-sm-4'>
                                <div className="form-group">
                                  <label for="site_slogan">Site Slogan</label>
                                  <input type="text" name="site_slogan" className="form-control" id="site_slogan" placeholder="Enter Site Slogan" value={addForm.site_slogan ? addForm.site_slogan : ''} onChange={handleChange} />
                                </div>
                              </div>
                              <div className='col-md-4 col-sm-4'>
                                <div className="form-group">
                                  <label for="web_url">Website Url</label>
                                  <input type="text" name="web_url" className="form-control" id="web_url" placeholder="Enter Web Url" value={addForm.web_url ? addForm.web_url : ''} onChange={handleChange} />
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4'>
                                <div className="form-group">
                                  <label for="address">Address</label>
                                  <input type="text" name="address" className="form-control" id="address" placeholder="Enter Site Name" value={addForm.address ? addForm.address : ''} onChange={handleChange} />
                                </div>
                              </div>
                              <div className='col-md-4 col-sm-4'>
                                <div className="form-group">
                                  <label for="mobile">Mobile</label>
                                  <input type="text" name="mobile" className="form-control" id="mobile" placeholder="Enter Site Slogan" value={addForm.mobile ? addForm.mobile : ''} onChange={handleChange} />
                                </div>
                              </div>
                              <div className='col-md-4 col-sm-4'>
                                <div className="form-group">
                                  <label for="telephone">Telephone</label>
                                  <input type="text" name="telephone" className="form-control" id="telephone" placeholder="Enter Web Url" value={addForm.telephone ? addForm.telephone : ''} onChange={handleChange} />
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-sm-6'>
                                <div className="form-group">
                                  <label for="map_location">Map Location</label>
                                  <textarea
                                    name='map_location'
                                    className="form-control" id="map_location" placeholder="Enter Map Iframe" value={addForm.map_location ? addForm.map_location : ''}
                                    onChange={handleChange}
                                  ></textarea>
                                </div>
                              </div>
                              <div className='col-md-6 col-sm-6'>
                                <div className="form-group">
                                  <label for="contact_detail">Contact Details</label>
                                  <textarea
                                    name='contact_detail'
                                    className="form-control" id="contact_detail" placeholder="Enter Conatct Detail" value={addForm.contact_detail ? addForm.contact_detail : ''}
                                    onChange={handleChange}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-header">
                            <h3 className="card-title">Social</h3>
                          </div>
                          <div className="card-body">
                            <div className='row'>
                              <div className='col-md-4 col-sm-4'>
                                <div className="form-group">
                                  <label for="email">Email</label>
                                  <input type="text" name="email" className="form-control" id="email" placeholder="Enter Site Name" value={addForm.email ? addForm.email : ''} onChange={handleChange} />
                                </div>
                              </div>
                              <div className='col-md-4 col-sm-4'>
                                <div className="form-group">
                                  <label for="facebook">Facebook</label>
                                  <input type="text" name="facebook" className="form-control" id="facebook" placeholder="Enter Site Slogan" value={addForm.facebook ? addForm.facebook : ''} onChange={handleChange} />
                                </div>
                              </div>
                              <div className='col-md-4 col-sm-4'>
                                <div className="form-group">
                                  <label for="whatsapp">WhatsApp</label>
                                  <input type="text" name="whatsapp" className="form-control" id="whatsapp" placeholder="Enter Web Url" value={addForm.whatsapp ? addForm.whatsapp : ''} onChange={handleChange} />
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4'>
                                <div className="form-group">
                                  <label for="skype">Skype</label>
                                  <input type="text" name="skype" className="form-control" id="skype" placeholder="Enter Site Name" value={addForm.skype ? addForm.skype : ''} onChange={handleChange} />
                                </div>
                              </div>
                              <div className='col-md-4 col-sm-4'>
                                <div className="form-group">
                                  <label for="twitter">Twitter</label>
                                  <input type="text" name="twitter" className="form-control" id="twitter" placeholder="Enter Site Slogan" value={addForm.twitter ? addForm.twitter : ''} onChange={handleChange} />
                                </div>
                              </div>
                              <div className='col-md-4 col-sm-4'>
                                <div className="form-group">
                                  <label for="instagram">Instagram</label>
                                  <input type="text" name="instagram" className="form-control" id="instagram" placeholder="Enter Web Url" value={addForm.instagram ? addForm.instagram : ''} onChange={handleChange} />
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4'>
                                <div className="form-group">
                                  <label for="youtube">Youtube</label>
                                  <input type="text" name="youtube" className="form-control" id="youtube" placeholder="Enter Site Name" value={addForm.youtube ? addForm.youtube : ''} onChange={handleChange} />
                                </div>
                              </div>
                              <div className='col-md-4 col-sm-4'>
                                <div className="form-group">
                                  <label for="googleplus">Google+</label>
                                  <input type="text" name="googleplus" className="form-control" id="googlepluss" placeholder="Enter Site Slogan" value={addForm.googleplus ? addForm.googleplus : ''} onChange={handleChange} />
                                </div>
                              </div>
                              <div className='col-md-4 col-sm-4'>
                                <div className="form-group">
                                  <label for="linked_in">Linked In</label>
                                  <input type="text" name="linked_in" className="form-control" id="linked_in" placeholder="Enter Web Url" value={addForm.linked_in ? addForm.linked_in : ''} onChange={handleChange} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-header">
                            <h3 className="card-title">Images</h3>
                          </div>
                          <div className="card-body">
                            <div className='row'>
                              <div className='col-md-6 col-sm-6'>
                                <div className="form-group">
                                  <label for="logo">Logo</label>
                                  <input type="file" name="logo" className="form-control" id="logo" placeholder="Logo" onChange={handleImageChange} />
                                  {
                                    addForm.logo_local_url ? <img src={addForm.logo_local_url} alt='' style={imgprev} /> : ''
                                  }
                                </div>
                              </div>
                              <div className='col-md-6 col-sm-6'>
                                <div className="form-group">
                                  <label for="fav">Fav</label>
                                  <input type="file" name="fav" className="form-control" id="fav" placeholder="Fav" onChange={handleFavChange} />
                                  {
                                    addForm.fav_local_url ? <img src={addForm.fav_local_url} alt='' style={imgprev} /> : ''
                                  }
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="card-header">
                            <h3 className="card-title">Meta Descritpion</h3>
                          </div>
                          <div className="card-body">
                            <div className='row'>
                              <div className='col-md-6 col-sm-6'>
                                <div className="form-group">
                                  <label for="meta_title">Meta Title</label>
                                  <input type="text" name="meta_title" className="form-control" id="meta_title" placeholder="Enter Meta Title" value={addForm.meta_title ? addForm.meta_title : ''} onChange={handleChange} />
                                </div>
                              </div>
                              <div className='col-md-6 col-sm-6'>
                                <div className="form-group">
                                  <label for="meta_key_words">Meta Keywords</label>
                                  <input type="text" name="meta_key_words" className="form-control" id="meta_key_words" placeholder="Enter Meta Key Words" value={addForm.meta_key_words ? addForm.meta_key_words : ''} onChange={handleChange} />
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-12 col-sm-12'>
                                <div className="form-group">
                                  <label for="meta_description">Meta Description</label>
                                  <textarea
                                    name='meta_description'
                                    className="form-control" id="meta_description" placeholder="Enter Map Iframe" value={addForm.meta_description ? addForm.meta_description : ''}
                                    onChange={handleChange}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer">
                            <button type="submit" className="btn btn-primary" onClick={handleAddForm}>Submit</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

  );
}