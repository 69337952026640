import React from 'react';
import { Link } from "react-router-dom";

export default function NotFound() {

    const mystyle = {
        background: "#0f8d13",
        padding: "250px",
        color: "#fff",
        fontSize: "65px",
        fontWeight: "bold",
    };


    return (
        <div className="wrapper">
            <h2 style={mystyle}>404 NotFound !!!<br></br><Link to="/">Home</Link></h2>
        </div >
    );
}