import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Helmet } from "react-helmet";
import { Button } from 'reactstrap';
import Edit from "./Edit";


let but = {
    color: "#fff",
    fontWeight: "bold",
    background: "#6c757d",
};

let imgprev = {
    width: "100%",
    height: "75px",
    objectFit: "contain",
}

export default function List() {

    const navigate = useNavigate();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    const [activePage, setActivePage] = useState(1);
    const [totalRow, setTotalRow] = useState(null);

    // state for conditional render of edit form
    const [isEditing, setIsEditing] = useState(false);
    // state for edit form inputs
    const [editForm, setEditForm] = useState({
        id: "",
        title: "",
        description: "",
        subtitle: "",
        featured_img: null,
        featured_img_data: null,
        rank: "",
        status: "",
    })

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect(() => {
        //   var my_script4 = new_script('/theme_admin/costume/js/main.js'); 

        fetch(`https://nyatapol.com/backend/api/service/all/${activePage}`, {

        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status == "Success") {
                        setIsLoaded(true);
                        setTotalRow(parseInt(result.total));
                        setItems(result.items);
                    } else {
                        // alert(result.status_message);
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    // update blogs on page after edit
    function onUpdateItem(updatedItem) {
        const updatedItems = items.map(
            item => {
                if (item.id === updatedItem.id) {
                    return updatedItem
                } else { return item }
            }
        )
        setItems(updatedItems)
    }

    function onDeleteItem(deletedItem) {
        const updatedItems = items.map(
            item => {
                if (item.id === deletedItem.id) {

                } else {
                    return item
                }
            }
        )
        setItems(updatedItems)
        // console.log(items);
    }

    // when POST request happens; auto-hides the form, pushes changes to display
    function handleItemUpdate(updatedItem) {
        // console.log(editForm);return false;  
        setIsEditing(false);
        onUpdateItem(updatedItem);
    }

    function cancelEdit() {
        setIsEditing(false);
    }

    // capture user input in edit form inputs
    function handleChange(e) {
        setEditForm({
            ...editForm,
            [e.target.name]: e.target.value
        })
    }

    function handleChangeTextarea(content, editor) {
        // console.log(editor);
        setEditForm({
            ...editForm,
            ['description']: content
        })
    }

    function handleImageChange(e) {
        if (e.target.files[0]) {
            var file = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function (event) {
                // The file's text will be printed here
                console.log(event.target.result);
                setEditForm({
                    ...editForm,
                    ['featured_img_data']: event.target.result,
                    ['featured_img']: URL.createObjectURL(e.target.files[0])
                });
            };

            reader.readAsDataURL(file);
        }

    }

    // needed logic for conditional rendering of the form - shows the customer you want when you want them, and hides it when you don't
    function changeEditState(item) {
        if (item.id === editForm.id) {
            setIsEditing(isEditing => !isEditing) // hides the form
        } else if (isEditing === false) {
            setIsEditing(isEditing => !isEditing) // shows the form
        }
    }

    // capture the customer you wish to edit, set to state
    function captureEdit(clickedItem) {
        let filtered = items.filter(item => item.id === clickedItem.id)
        setEditForm(filtered[0])
    }

    // soft delete
    function handelDelete(id) {

        fetch(`https://nyatapol.com/backend/api/service/soft_delete/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(resp => resp.json())
            .then(resp => {
                if (resp.status == "Success") {
                    // alert(resp.status_message); 
                    // onDeleteItem(resp.data);
                    // navigate('/admin/item');
                    window.location.reload(false);
                } else {
                    // alert(resp.status_message);  
                }
            })
    }

    function handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        setActivePage(pageNumber);
        fetch(`https://nyatapol.com/backend/api/service/all/${pageNumber}`)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status == "Success") {
                        setTotalRow(parseInt(result.total));
                        setItems(result.items);
                    } else {
                        // alert(result.status_message);
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }

    return (
        <section className="content">
            {/* <Helmet>  
                <script src="/theme_admin/costume/js/main.js"></script> 
            </Helmet> */}
            {isEditing ?
                (<Edit
                    editForm={editForm}
                    handleChange={handleChange}
                    handleChangeTextarea={handleChangeTextarea}
                    handleItemUpdate={handleItemUpdate}
                    cancelEdit={cancelEdit}
                    handleImageChange={handleImageChange}
                />) : (
                    <><div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        {/* <h3 className="card-title">DataTable with default features</h3> */}
                                        <Link to="/admin/service/add" className="nav-link btn btn-sm" style={but}>
                                            <p>Add New</p>
                                        </Link>
                                    </div>
                                    <div className="card-body">
                                        <table id="example1" className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Sub title</th>
                                                    <th>Featured Image</th>
                                                    <th>Created</th>
                                                    <th>Updated</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (items.length > 0) ?
                                                        (items.map(val => (
                                                            <tr key={val.id.toString()}>
                                                                <td>{val.title}</td>
                                                                <td>{val.subtitle}</td>
                                                                <td>
                                                                    {
                                                                        val.featured_img ? <img src={val.featured_img} alt='' style={imgprev} /> : <></>
                                                                    }
                                                                </td>
                                                                <td>{val.created}</td>
                                                                <td>{val.updated}</td>
                                                                <td>
                                                                    {val.status == '1' ? (
                                                                        'Enabled'
                                                                    ) : (
                                                                        'Dissabled'
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <Button onClick={() => {
                                                                        captureEdit(val);
                                                                        changeEditState(val);
                                                                    }}>
                                                                        <i className="far fa-edit nav-icon"></i>
                                                                    </Button>
                                                                    <Button onClick={() => {
                                                                        handelDelete(val.id);
                                                                    }}>
                                                                        <i className="far fa-trash-alt nav-icon"></i>
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        ))) : (<tr><td colSpan="7">No Records Found !!!</td></tr>)

                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Sub title</th>
                                                    <th>Featured Image</th>
                                                    <th>Created</th>
                                                    <th>Updated</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        {(items.length > 0) ? (
                                            <div>
                                                <Pagination
                                                    activePage={activePage}
                                                    itemsCountPerPage={6}
                                                    totalItemsCount={totalRow}
                                                    pageRangeDisplayed={3}
                                                    onChange={handlePageChange.bind(this)}
                                                />
                                            </div>) : (<></>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div id='scpt'>

                        </div></>)}
        </section>
    );
} 