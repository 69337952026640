import React, { useEffect, useState } from 'react';
import useScript from '../../../components/UseScript';
import { Helmet } from "react-helmet";

export default function AdminFooter() {

  const [error, setError] = useState(null);
  const [settings, setSettings] = useState([]);

  const mystyle = {
    background: "#0f8d13",
    padding: "25px",
    color: "#fff",
    fontSize: "20px",
    fontWeight: "bold",
  };

  useEffect(() => {
    fetch(`https://nyatapol.com/backend/web_api/home/site_settings`, {

    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.status == "success") {
            setSettings(result.site_settings);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setError(error);
        }
      )
  }, [])

  return (
    <div className="adminfoot">
      {/* <Helmet>
        <script src='../../../theme_admin_backup/plugins/jquery/jquery.min.js'></script>
        <script src='../../../theme_admin_backup/plugins/bootstrap/js/bootstrap.bundle.min.js'></script>
        <script src='../../../theme_admin_backup/dist/js/adminlte.js'></script>
        <script src='../../../theme_admin_backup/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js'></script>
      </Helmet> */}
      {/* <!-- Main Footer --> */}
      <footer className="main-footer">
        <strong>Copyright &copy; {new Date().getFullYear()} <a href={settings.web_url}>{settings.site_name}</a>.</strong>
        All rights reserved.
      </footer>
      {/* <!-- REQUIRED SCRIPTS --> */}
      {/* <!-- jQuery --> */}

      {/* {useScript('../../../theme_admin_backup/plugins/jquery/jquery.min.js')} */}
      {/* <!-- Bootstrap --> */}
      {/* {useScript('../../../theme_admin_backup/plugins/bootstrap/js/bootstrap.bundle.min.js')} */}
      {/* <!-- overlayScrollbars --> */}
      {/* {useScript('../../../theme_admin_backup/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js')} */}
      {/* <!-- AdminLTE App --> */}
      {/* {useScript('../../../theme_admin_backup/dist/js/adminlte.js')} */}


    </div>
  );
}