import React from "react";
import { Navigate, Route, Outlet, Routes, useRoutes, useLocation, Link, useParams } from "react-router-dom";
import Add from "./Add";
import List from "./List";

export default function NestedRoutes() {

  const { pathname } = useLocation();

  return (
    <div>
      <Routes>
        <Route path="/" element={<Items />}>
          <Route index element={<List />} />
          <Route path="/add" element={<Add />} />
        </Route>
      </Routes>
    </div>
  );
}

function Items() {
  const { params } = useParams();
  // console.log(params);return false;
  return (
    <div className="blogadmin">
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Client Category</h1>
              </div>
              {/* <!-- /.col --> */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/admin/dashboard">Home</Link></li>
                  <li className="breadcrumb-item active">Client Category</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            {/* <!-- Info boxes --> */}
            <div className="row">
              <div className="col-12 col-sm-6 col-md-12">
                <Outlet />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}