import React, { useEffect, useState } from "react";
import FieldComponent from "../../../components/FieldComponent";

import { Editor } from "@tinymce/tinymce-react";

// directly exported not as default
import { ContentType } from "../../../components/values/ContentType";
import { YesNo } from "../../../components/values/YesNo";

// exported as deafult
import Status from "../../../components/values/Status";

let imgprev = {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    margin: "20px",
}

function Edit({ editForm, handleChange, handleChangeTextarea, handleItemUpdate, cancelEdit, handleImageChange }) {
    let displayNone = {
        display: "none",
    }

    let displayBlock = {
        display: "block",
    }
    let { id, title, description, sub_title, featured_image, featured_img_data, type, status, show_on_menu, show_on_footer_menu, external_url, parent_id, order_no } = editForm

    const [parent, setParent] = useState('<option value="NULL">Root</option>');

    useEffect(() => {
        //   var my_script4 = new_script('/theme_admin/costume/js/main.js'); 

        fetch(`https://nyatapol.com/backend/api/content/dataForForm/${parent_id}`, {

        })
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(result.data); return false;
                    if (result.status == "Success") {
                        setParent(result.data);
                        // $('#parent_id').append(result.data);
                        // console.log(parent); return false;
                    } else {
                        // alert(result.status_message);
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {

                }
            )
    }, [])
    // PATCH request; calls handleItemUpdate to push changes to the page
    function handleEditForm(e) {
        // console.log(editForm);return false;
        e.preventDefault();
        fetch(`https://nyatapol.com/backend/api/content/edit/${id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(editForm),
        })
            .then(resp => resp.json())
            .then(updatedItem => {
                console.log(updatedItem.data);
                handleItemUpdate(updatedItem.data)
            })
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Edit Content</h3>
                        </div>
                        <div className="card-body">
                            <form id="quickForm">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <FieldComponent
                                                name='title'
                                                type='text'
                                                fieldType='input'
                                                label='Title'
                                                handleChange={handleChange}
                                                values={title}
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-3 col-xs-12">

                                            <FieldComponent
                                                name='type'
                                                type='select'
                                                fieldType='select'
                                                label='Select Type'
                                                handleChange={handleChange}
                                                values={ContentType}
                                                selected={type}
                                            />
                                        </div>
                                        <div className="col-md-3 ext_url" style={type == 'others' ? (displayBlock) : (displayNone)} id="external_url_bx">
                                            <FieldComponent
                                                name='external_url'
                                                type='text'
                                                fieldType='input'
                                                label='External Url'
                                                handleChange={handleChange}
                                                isrequired='yes'
                                                values={external_url}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="form-group">
                                                <label>Select Parent</label>
                                                <select name="parent_id" className="form-control selct2" id="parent_id" dangerouslySetInnerHTML={{ __html: parent }} onChange={handleChange}>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <FieldComponent
                                                name='sub_title'
                                                type='text'
                                                fieldType='input'
                                                label='Subtitle'
                                                handleChange={handleChange}
                                                values={sub_title}
                                            />
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <FieldComponent
                                                name='featured_img'
                                                type='file'
                                                fieldType='input'
                                                label='Featured Image'
                                                handleChange={handleImageChange}
                                            />
                                            {
                                                featured_image ? <img src={featured_image} alt='' style={imgprev} /> : <></>
                                            }
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            {/* <FieldComponent
                                                name='description'
                                                type='textarea'
                                                fieldType='textarea'
                                                label='Description'
                                                handleChange={handleChange}
                                                values={description}
                                            /> */}
                                            <div className="form-group">
                                                <label>Description</label>
                                                <Editor
                                                    value={description}
                                                    init={{
                                                        height: 500,
                                                        menubar: true
                                                    }}
                                                    onEditorChange={handleChangeTextarea}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-sm-3 col-xs-12">
                                            <FieldComponent
                                                name='show_on_menu'
                                                type='select'
                                                fieldType='select'
                                                label='Show On Menu'
                                                handleChange={handleChange}
                                                values={YesNo}
                                                selected={show_on_menu}
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-3 col-xs-12">
                                            <FieldComponent
                                                name='show_on_footer_menu'
                                                type='select'
                                                fieldType='select'
                                                label='Show On Footer Menu'
                                                handleChange={handleChange}
                                                values={YesNo}
                                                selected={show_on_footer_menu}
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-3 col-xs-12">
                                            <FieldComponent
                                                name='order_no'
                                                type='number'
                                                fieldType='input'
                                                label='Order Number'
                                                handleChange={handleChange}
                                                values={order_no}
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-3 col-xs-12">
                                            <FieldComponent
                                                name='status'
                                                type='select'
                                                fieldType='select'
                                                label='Status'
                                                handleChange={handleChange}
                                                values={Status}
                                                selected={status}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary" onClick={handleEditForm}>Submit</button>
                            <button className="btn btn-primary" onClick={cancelEdit}>Cancel</button>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    )
}
export default Edit