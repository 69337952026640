import React, { useEffect, useState } from "react";
import Partner1 from "../../../theme_web/assets/img/partner-geotrust.jpg";
import Partner2 from "../../../theme_web/assets/img/partner-symantec.jpg";
import Partner3 from "../../../theme_web/assets/img/partner-thawte.jpg";
import useScript from '../../../components/UseScript';

export default function Footer() {
    const [settings, setSettings] = useState([]);
    useEffect(() => {
        document.body.classList.add('front_side');
        fetch(`https://nyatapol.com/backend/web_api/home/site_settings`, {

        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status == "success") {
                        setSettings(result.site_settings);
                    } else {
                        // setError(result.status_message);
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    // setError(error);
                }
            )
    }, [])

    return (
        <div className="web_footer">
            <footer className="footer section bd-container">
                <div className="footer__container bd-grid">
                    <div className="footer__content">
                        <a href="#" className="footer__logo">Nyatapol</a>
                        <span className="footer__description">Technologies</span>
                        <div>
                            <a href={settings.facebook} className="footer__social" target="_blank"><i className='bx bxl-facebook'></i></a>
                            <a href={settings.instagram} className="footer__social" target="_blank"><i className='bx bxl-instagram'></i></a>
                            <a href={settings.twitter} className="footer__social" target="_blank"><i className='bx bxl-twitter'></i></a>
                        </div>
                    </div>

                    <div className="footer__content">
                        <h3 className="footer__title">Company</h3>
                        <ul>
                            <li><a href="#about" className="footer__link">About Us</a></li>
                            <li><a href="#" className="footer__link">What We Do</a></li>
                            <li><a href="#" className="footer__link">How We Do</a></li>
                            <li><a href="#contact" className="footer__link">Contact</a></li>
                        </ul>
                    </div>

                    <div className="footer__content">
                        <h3 className="footer__title">Services</h3>
                        <ul>
                            <li><a href="#services" className="footer__link">Software Development</a></li>
                            <li><a href="#services" className="footer__link">Web Development</a></li>
                            <li><a href="#services" className="footer__link">Network Consultancy</a></li>
                            <li><a href="#services" className="footer__link">Biometrics Solution</a></li>
                        </ul>
                    </div>

                    <div className="footer__content ">
                        <h3 className="footer__title partners">Partners</h3>
                        <ul className="partners__list">
                            <li className="partner__img"><img src={Partner1} href="#" className="partners__img" /></li>
                            <li><img src={Partner2} href="#" className="partners__img" /></li>
                            <li><img src={Partner3} href="#" className="partners__img" /></li>
                        </ul>
                    </div>
                </div>

                <p className="footer__copy">&#169; {new Date().getFullYear()} Nyatapol Technologies Limited. All right reserved</p>
            </footer>
            {/* {useScript('../theme_web/assets/js/main.js')} */}
        </div>
    );
}