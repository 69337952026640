import React from 'react';

export default function Dashboard() {



  return (
    <div className="dashboard">
      {/* <!-- Content Wrapper. Contains page content --> */}
      <div className="content-wrapper">
        {/* <!-- Content Header (Page header) --> */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Dashboard v2</h1>
              </div>
              {/* <!-- /.col --> */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">Dashboard v2</li>
                </ol>
              </div>
              {/* <!-- /.col --> */}
            </div>
            {/* <!-- /.row --> */}
          </div>
          {/* <!-- /.container-fluid --> */}
        </div>
        {/* <!-- /.content-header --> */}

        {/* <!-- Main content --> */}
        <section className="content">
          <div className="container-fluid">
            {/* <!-- Info boxes --> */}
            <div className="row">
              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box">
                  <span className="info-box-icon bg-info elevation-1"><i className="fas fa-cog"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-text">CPU Traffic</span>
                    <span className="info-box-number">
                      10
                      <small>%</small>
                    </span>
                  </div>
                  {/* <!-- /.info-box-content --> */}
                </div>
                {/* <!-- /.info-box --> */}
              </div>
              {/* <!-- /.col --> */}
              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box mb-3">
                  <span className="info-box-icon bg-danger elevation-1"><i className="fas fa-thumbs-up"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-text">Likes</span>
                    <span className="info-box-number">41,410</span>
                  </div>
                  {/* <!-- /.info-box-content --> */}
                </div>
                {/* <!-- /.info-box --> */}
              </div>
              {/* <!-- /.col --> */}

              {/* <!-- fix for small devices only --> */}
              <div className="clearfix hidden-md-up"></div>

              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box mb-3">
                  <span className="info-box-icon bg-success elevation-1"><i className="fas fa-shopping-cart"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-text">Sales</span>
                    <span className="info-box-number">760</span>
                  </div>
                  {/* <!-- /.info-box-content --> */}
                </div>
                {/* <!-- /.info-box --> */}
              </div>
              {/* <!-- /.col --> */}
              <div className="col-12 col-sm-6 col-md-3">
                <div className="info-box mb-3">
                  <span className="info-box-icon bg-warning elevation-1"><i className="fas fa-users"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-text">New Members</span>
                    <span className="info-box-number">2,000</span>
                  </div>
                  {/* <!-- /.info-box-content --> */}
                </div>
                {/* <!-- /.info-box --> */}
              </div>
              {/* <!-- /.col --> */}
            </div>
            {/* <!-- /.row --> */}

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Dashboard</h5>

                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse">
                        <i className="fas fa-minus"></i>
                      </button>
                      <div className="btn-group">
                        <button type="button" className="btn btn-tool dropdown-toggle" data-toggle="dropdown">
                          <i className="fas fa-wrench"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" role="menu">
                          <a href="#" className="dropdown-item">Action</a>
                          <a href="#" className="dropdown-item">Another action</a>
                          <a href="#" className="dropdown-item">Something else here</a>
                          <a className="dropdown-divider"></a>
                          <a href="#" className="dropdown-item">Separated link</a>
                        </div>
                      </div>
                      <button type="button" className="btn btn-tool" data-card-widget="remove">
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  {/* <!-- /.card-header --> */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <p className="text-center">
                          <strong>Dashboard</strong>
                        </p>
                      </div>
                    </div>
                    {/* <!-- /.row --> */}
                  </div>
                  {/* <!-- ./card-body --> */}
                  <div className="card-footer">
                    <div className="row">
                      <div className="col-sm-3 col-6">
                        <div className="description-block border-right">
                          <span className="description-percentage text-success"><i className="fas fa-caret-up"></i> 17%</span>
                          <h5 className="description-header">$35,210.43</h5>
                          <span className="description-text">TOTAL REVENUE</span>
                        </div>
                      </div>
                      <div className="col-sm-3 col-6">
                        <div className="description-block border-right">
                          <span className="description-percentage text-warning"><i className="fas fa-caret-left"></i> 0%</span>
                          <h5 className="description-header">$10,390.90</h5>
                          <span className="description-text">TOTAL COST</span>
                        </div>
                        {/* <!-- /.description-block --> */}
                      </div>
                      <div className="col-sm-3 col-6">
                        <div className="description-block border-right">
                          <span className="description-percentage text-success"><i className="fas fa-caret-up"></i> 20%</span>
                          <h5 className="description-header">$24,813.53</h5>
                          <span className="description-text">TOTAL PROFIT</span>
                        </div>
                        {/* <!-- /.description-block --> */}
                      </div>
                      <div className="col-sm-3 col-6">
                        <div className="description-block">
                          <span className="description-percentage text-danger"><i className="fas fa-caret-down"></i> 18%</span>
                          <h5 className="description-header">1200</h5>
                          <span className="description-text">GOAL COMPLETIONS</span>
                        </div>
                        {/* <!-- /.description-block --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <aside className="control-sidebar control-sidebar-dark">
      </aside>
    </div>
  );
}