import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import FieldComponent from "../../../components/FieldComponent";

import { Editor } from "@tinymce/tinymce-react";

// import CKEditor from "react-ckeditor-component";
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Helmet } from "react-helmet";
import { Button } from 'reactstrap';
import EditBlog from "./EditBlog";

let imgprev = {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    margin: "20px",
}

export default function AddBlog() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    // this.uploadSingleFile = this.uploadSingleFile.bind(this)
    // this.upload = this.upload.bind(this)

    const [addForm, setAddForm] = useState({
        id: "",
        title: "",
        description: "",
        subtitle: "",
        featured_img: null,
        featured_img_data: null,
        status: "",
    })

    const navigate = useNavigate();

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    function handleAddForm(e) {
        // console.log(addForm);return false;
        e.preventDefault();
        fetch(`https://nyatapol.com/backend/api/blog/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8"
                // "Content-Type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
            },
            body: JSON.stringify(addForm),
        })
            .then(resp => resp.json())
            .then(updatedBlog => {
                console.log(updatedBlog.status_message);
                // alert('success');
                // handleBlogUpdate(updatedBlog.data)
                navigate('/admin/blog/blogs')
            })
    }

    // capture user input in edit form inputs
    function handleChange(e) {
        // console.log(e.target.value);return false;
        // if (e.target.files[0]) { 
        //     var file = e.target.files[0]; 
        //     var reader = new FileReader();
        //     reader.onload = function(event) {
        //         // The file's text will be printed here
        //         console.log(event.target.result);
        //         setAddForm({
        //                 ...addForm,
        //                 ['featured_img_data']: event.target.result,
        //                 ['featured_img']: e.target.files[0]
        //                 });
        //     };

        //     reader.readAsText(file);

        //     console.log(addForm);
        // }else{
        //     setAddForm({
        //         ...addForm,
        //         [e.target.name]: e.target.value
        //         })
        // }
        // if(e.editor.getData()){
        //     var newContent = e.editor.getData();
        //     setAddForm({
        //         ...addForm,
        //         ['description']: newContent
        //         }) 
        // }else{
        //     setAddForm({
        //         ...addForm,
        //         [e.target.name]: e.target.value
        //         }) 
        // }
        setAddForm({
            ...addForm,
            [e.target.name]: e.target.value
        })

    }

    function handleChangeTextarea(content, editor) {
        // console.log(editor);
        setAddForm({
            ...addForm,
            ['description']: content
        })
    }

    function handleImageChange(e) {
        if (e.target.files[0]) {
            // console.log("Featured Image: ", URL.createObjectURL(e.target.files[0])); 

            // const reader = new FileReader();
            // reader.addEventListener("load", () => {
            // //   setImgData(reader.result);
            //   setAddForm({
            //     ...addForm,
            //     ['featured_img_data']: reader.result,
            //     ['featured_img']: e.target.files[0]
            //     });
            // });
            // reader.readAsDataURL(e.target.files[0]);
            var file = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function (event) {
                // The file's text will be printed here
                console.log(event.target.result);
                setAddForm({
                    ...addForm,
                    ['featured_img_data']: event.target.result,
                    ['featured_img']: URL.createObjectURL(e.target.files[0])
                });
            };

            reader.readAsDataURL(file);
        }
    }


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card card-primary">
                        <form id="quickForm">
                            <div className="card-header">
                                <h3 className="card-title">Add Blog</h3>
                            </div>
                            <div className="card-body">
                                <FieldComponent
                                    name='title'
                                    type='text'
                                    fieldType='input'
                                    label='Title'
                                    handleChange={handleChange}
                                />
                                <div className="form-group">
                                    <label>Description</label>
                                    <Editor
                                        // value={description}
                                        init={{
                                            height: 500,
                                            menubar: true
                                        }}
                                        onEditorChange={handleChangeTextarea}
                                    />
                                </div>
                                {/* <FieldComponent
                                    name='description'
                                    type='textarea'
                                    fieldType='textarea'
                                    label='Description'
                                    handleChange={handleChange}
                                /> */}
                                <div className="form-group">
                                    <label for="subtitle">Sub title</label>
                                    <input type="text" name="subtitle" className="form-control" id="subtitle" placeholder="Subtitle" onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label for="featured_img">Featured Image</label>
                                    <input type="file" name="featured_img" className="form-control" id="featured_img" placeholder="Featured Image" onChange={handleImageChange} />
                                    {
                                        addForm.featured_img ? <img src={addForm.featured_img} alt='' style={imgprev} /> : <></>
                                    }
                                </div>
                                <div className="form-group">
                                    <label for="status">Status:</label>
                                    <select name="status" id="status" className="form-control" onChange={handleChange}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button type="submit" className="btn btn-primary" onClick={handleAddForm}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
} 