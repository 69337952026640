const Type = [
    {
        name: "1",
        vals: "1",
    },
    {
        name: "2",
        vals: "2",
    },
    {
        name: "3",
        vals: "3",
    },
];

export default Type;