import React from "react";
import { Navigate, Route, Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";

import AdminHeader from "../view/admin/layout/AdminHeader";
import AdminFooter from "../view/admin/layout/AdminFooter";

function PrivateOutlet() {
    const auth = localStorage.getItem("isAuthenticated");
    return auth ? <div>
        {/* <Helmet>  
                        <script src="/theme_admin/costume/js/main.js"></script> 
                    </Helmet> */}
        <AdminHeader />
        <Outlet />
        <AdminFooter />
    </div> : <Navigate to="/signin" />;


}

export default PrivateOutlet;