
import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
// Importing toastify module
import { ToastContainer } from 'react-toastify';

// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './view/admin/Dashboard';
import SiteSettings from './view/admin/settings/Site';
import BlogRoute from './view/admin/blog/BlogRoute';
import ItemRoutes from './view/admin/item/NestedRoutes';
import ContentRoutes from './view/admin/content/NestedRoutes';
import SliderRoutes from './view/admin/slider/NestedRoutes';
import ServiceRoutes from './view/admin/service/NestedRoutes';
import PortfolioRoutes from './view/admin/portfolio/NestedRoutes';
import TestimonialsRoutes from './view/admin/testimonials/NestedRoutes';
import ClientsRoutes from './view/admin/clients/NestedRoutes';
import ClientCategoryRoutes from './view/admin/client_cat/NestedRoutes';
import SubscriberRoutes from './view/admin/subscriber/NestedRoutes';
import FeedbackRoutes from './view/admin/feedback/NestedRoutes';

import Home from './view/website/Home';
import BlogList from './view/website/blog/BlogList';
import BlogDetail from './view/website/blog/BlogDetail';
import NotFound from './view/NotFound';
import Signin from './view/website/Signin';
// import PrivateRoute from './components/PrivateRoute';
import PrivateOutlet from './components/PrivateOutlet';
import PublicOutlet from './components/PublicOutlet';

import useScript from './components/UseScript';

function App() {
  return (
    <div className="wrapper">
      <BrowserRouter>
        <Routes>

          <Route path="/signin" element={<Signin />} />
          <Route path='*' element={<NotFound />} />
          {/* <Route path="/admin/dashboard" element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          } />  */}
          <Route path="/" element={<PublicOutlet />}>
            <Route path="/" element={<Home />} />
            <Route path="/blogs" element={<BlogList />} />
            <Route path="/blogs/*" element={<BlogDetail />} />
          </Route>
          <Route path="/admin" element={<PrivateOutlet />}>
            <Route path="/admin" element={<Dashboard />} />
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/site_settings" element={<SiteSettings />} />
            <Route path="/admin/blog/*" element={<BlogRoute />} />
            <Route path="/admin/item/*" element={<ItemRoutes />} />
            <Route path="/admin/content/*" element={<ContentRoutes />} />
            <Route path="/admin/slider/*" element={<SliderRoutes />} />
            <Route path="/admin/service/*" element={<ServiceRoutes />} />
            <Route path="/admin/portfolio/*" element={<PortfolioRoutes />} />
            <Route path="/admin/testimonials/*" element={<TestimonialsRoutes />} />
            <Route path="/admin/clients/*" element={<ClientsRoutes />} />
            <Route path="/admin/client_category/*" element={<ClientCategoryRoutes />} />
            <Route path="/admin/subscriber/*" element={<SubscriberRoutes />} />
            <Route path="/admin/feedback/*" element={<FeedbackRoutes />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={5000} />
      {/* {useScript('./theme_web/assets/js/main.js')} */}
    </div>
  );
}


export default App; 
