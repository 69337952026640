import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FieldComponent from "../../../components/FieldComponent";

import { Editor } from "@tinymce/tinymce-react";

// directly exported not as default
import { ContentType } from "../../../components/values/ContentType";
import { YesNo } from "../../../components/values/YesNo";

// exported as deafult
import Status from "../../../components/values/Status";

let imgprev = {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    margin: "20px",
}

let displayNone = {
    display: "none",
}

let displayBlock = {
    display: "block",
}

export default function Add() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [parent, setParent] = useState('<option value="NULL">Root</option>');

    const [disp, setDisp] = useState(displayNone);

    // this.uploadSingleFile = this.uploadSingleFile.bind(this)
    // this.upload = this.upload.bind(this)

    const [addForm, setAddForm] = useState({
        id: "",
        title: "",
        description: "",
        subtitle: "",
        featured_img: null,
        featured_img_data: null,
        type: "",
        status: "",
        show_on_menu: "",
        show_on_footer_menu: "",
        external_url: "",
        parent_id: 0,
        order_no: 0,
    });

    const navigate = useNavigate();

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect(() => {
        //   var my_script4 = new_script('/theme_admin/costume/js/main.js'); 

        fetch(`https://nyatapol.com/backend/api/content/dataForForm`, {

        })
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(result.data); return false;
                    if (result.status == "Success") {
                        setParent(result.data);
                        // $('#parent_id').append(result.data);
                        // console.log(parent); return false;
                    } else {
                        // alert(result.status_message);
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    function handleAddForm(e) {
        // console.log(addForm);return false;
        e.preventDefault();
        fetch(`https://nyatapol.com/backend/api/content/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(addForm),
        })
            .then(resp => resp.json())
            .then(updatedItem => {
                console.log(updatedItem.status_message);
                // alert('success'); 
                navigate('/admin/content')
            })
    }

    // capture user input in edit form inputs
    function handleChange(e) {
        setAddForm({
            ...addForm,
            [e.target.name]: e.target.value
        })
        if (e.target.name == "type") {
            isOthers(e);
        }
    }

    function handleChangeTextarea(content, editor) {
        // console.log(editor);
        setAddForm({
            ...addForm,
            ['description']: content
        })
    }

    function isOthers(e) {
        // console.log(e.target.value);
        if (e.target.value == 'others') {
            // console.log(e.target.value);
            setDisp(displayBlock);
        } else {
            setDisp(displayNone);
        }
    }

    function handleImageChange(e) {
        if (e.target.files[0]) {
            var file = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function (event) {
                // The file's text will be printed here
                console.log(event.target.result);
                setAddForm({
                    ...addForm,
                    ['featured_img_data']: event.target.result,
                    ['featured_img']: URL.createObjectURL(e.target.files[0])
                });
            };

            reader.readAsDataURL(file);
        }
    }


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card card-primary">
                        <form id="quickForm">
                            <div className="card-header">
                                <h3 className="card-title">Add Content</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <FieldComponent
                                            name='title'
                                            type='text'
                                            fieldType='input'
                                            label='Title'
                                            handleChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">

                                        <FieldComponent
                                            name='type'
                                            type='select'
                                            fieldType='select'
                                            label='Select Type'
                                            handleChange={handleChange}
                                            values={ContentType}
                                        />
                                    </div>
                                    <div className="col-md-3 ext_url" style={disp} id="external_url_bx">
                                        <FieldComponent
                                            name='external_url'
                                            type='text'
                                            fieldType='input'
                                            label='External Url'
                                            handleChange={handleChange}
                                            isrequired='yes'
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                        <div className="form-group">
                                            <label>Select Parent</label>
                                            <select name="parent_id" className="form-control selct2" id="parent_id" dangerouslySetInnerHTML={{ __html: parent }} onChange={handleChange}>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                        <FieldComponent
                                            name='subtitle'
                                            type='text'
                                            fieldType='input'
                                            label='Subtitle'
                                            handleChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                        {/* <div className="form-group">
                                            <label for="featured_img">Featured Image</label>
                                            <input type="file" name="featured_img" className="form-control" id="featured_img" placeholder="Featured Image" onChange={handleImageChange} />

                                        </div> */}
                                        <FieldComponent
                                            name='featured_img'
                                            type='file'
                                            fieldType='input'
                                            label='Featured Image'
                                            handleChange={handleImageChange}
                                        />
                                        {
                                            addForm.featured_img ? <img src={addForm.featured_img} alt='' style={imgprev} /> : <></>
                                        }
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        {/* <FieldComponent
                                            name='description'
                                            type='textarea'
                                            fieldType='textarea'
                                            label='Description'
                                            handleChange={handleChange}
                                        /> */}
                                        <div className="form-group">
                                            <label>Description</label>
                                            <Editor
                                                // value={description}
                                                init={{
                                                    height: 500,
                                                    menubar: true
                                                }}
                                                onEditorChange={handleChangeTextarea}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                        <FieldComponent
                                            name='show_on_menu'
                                            type='select'
                                            fieldType='select'
                                            label='Show On Menu'
                                            handleChange={handleChange}
                                            values={YesNo}
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                        <FieldComponent
                                            name='show_on_footer_menu'
                                            type='select'
                                            fieldType='select'
                                            label='Show On Footer Menu'
                                            handleChange={handleChange}
                                            values={YesNo}
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                        <FieldComponent
                                            name='order_no'
                                            type='number'
                                            fieldType='input'
                                            label='Order Number'
                                            handleChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                        {/* <div className="form-group">
                                            <label for="status">Status:</label>
                                            <select name="status" id="status" className="form-control" onChange={handleChange}>
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </select>
                                        </div> */}
                                        <FieldComponent
                                            name='status'
                                            type='select'
                                            fieldType='select'
                                            label='Status'
                                            handleChange={handleChange}
                                            values={Status}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button type="submit" className="btn btn-primary" onClick={handleAddForm}>Submit</button>
                            </div>
                        </form >
                    </div >
                </div >
            </div >
        </div >
    );
} 