import React from 'react';
import { Link } from 'react-router-dom';  

export default function BlogList() {
  return(
    <div className="wrapper">
          <h2>This is Blog List Page</h2>
          <h4>Here you can find a list of blogs !!!</h4> 
    </div>
  );
}