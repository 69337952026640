import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import "../../../theme_web/assets/css/styles_web.css";
import StaticLogo from "../../../theme_web/assets/img/logo.png";
import useScript from '../../../components/UseScript';
import { Helmet } from "react-helmet";
import { NavHashLink } from 'react-router-hash-link';

export default function Header() {

  const [error, setError] = useState(null);
  const [settings, setSettings] = useState([]);
  const [dynamicLogo, setDynamicLogo] = useState('');
  const [dynamicSiteName, setDynamicSiteName] = useState('');

  let staticSiteName = "Nyatapol Technologies";

  useEffect(() => {
    document.body.classList.add('front_side');
    fetch(`https://nyatapol.com/backend/web_api/home/site_settings`, {

    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.status == "success") {
            setSettings(result.site_settings);
          } else {
            setError(result.status_message);
            setDynamicLogo(result.site_settings.logo);
            setDynamicSiteName(result.site_settings.site_name)
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setError(error);
        }
      )
  }, [])

  return (
    <div className="web_head">
      <header className="l-header" id="header">
        {/* <Helmet>
          <script src="/theme_web/assets/js/main.js"></script>
        </Helmet> */}
        <nav className="nav bd-container">

          <a href="#" className="nav__logo">
            <img src={
              dynamicLogo ? (dynamicLogo) : (StaticLogo)
            }
              alt="Nyatapol Logo" />
            <p>{dynamicSiteName ? (dynamicSiteName) : (staticSiteName)}</p>
          </a>

          <div className="nav__menu" id="nav-menu">
            <ul className="nav__list">
              <NavHashLink
                smooth
                to="#home"
              >
                <li className="nav__item"><a className="nav__link">
                  Home
                </a></li>
              </NavHashLink>
              <NavHashLink
                smooth
                to="#about"
              >
                <li className="nav__item"><a className="nav__link">
                  About Us
                </a></li>
              </NavHashLink>
              <NavHashLink
                smooth
                to="#services"
              >
                <li className="nav__item"><a className="nav__link">
                  Services
                </a></li>
              </NavHashLink>
              <NavHashLink
                smooth
                to="#products"
              >
                <li className="nav__item"><a className="nav__link">
                  Products
                </a></li>
              </NavHashLink>
              <NavHashLink
                smooth
                to="#contact"
              >
                <li className="nav__item"><a className="nav__link">
                  Contact Us
                </a></li>
              </NavHashLink>
            </ul>
          </div>

          <div className="nav__toggle" id="nav-toggle">
            <i className='bx bx-menu'></i>
          </div>
        </nav>
        {useScript('../theme_web_backup/assets/js/main.js')}
      </header>
    </div>
  );
}