import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FieldComponent from "../../../components/FieldComponent";

// exported as deafult
import Status from "../../../components/values/Status";


let imgprev = {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    margin: "20px",
}

export default function Add() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    // this.uploadSingleFile = this.uploadSingleFile.bind(this)
    // this.upload = this.upload.bind(this)

    const [addForm, setAddForm] = useState({
        id: "",
        name: "",
        description: "",
        designation: "",
        featured_img: null,
        featured_img_data: null,
        status: "",
    })

    const navigate = useNavigate();

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    function handleAddForm(e) {
        // console.log(addForm);return false;
        e.preventDefault();
        fetch(`https://nyatapol.com/backend/api/testimonials/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(addForm),
        })
            .then(resp => resp.json())
            .then(updatedItem => {
                console.log(updatedItem.status_message);
                // alert('success');
                // handleBlogUpdate(updatedItem.data)
                navigate('/admin/testimonials')
            })
    }

    // capture user input in edit form inputs
    function handleChange(e) {
        setAddForm({
            ...addForm,
            [e.target.name]: e.target.value
        })

    }

    function handleImageChange(e) {
        if (e.target.files[0]) {
            var file = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function (event) {
                // The file's text will be printed here
                console.log(event.target.result);
                setAddForm({
                    ...addForm,
                    ['featured_img_data']: event.target.result,
                    ['featured_img']: URL.createObjectURL(e.target.files[0])
                });
            };

            reader.readAsDataURL(file);
        }
    }


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card card-primary">
                        <form id="quickForm">
                            <div className="card-header">
                                <h3 className="card-title">Add Testimonials</h3>
                            </div>
                            <div className="card-body">
                                <FieldComponent
                                    name='name'
                                    type='text'
                                    fieldType='input'
                                    label='Name'
                                    handleChange={handleChange}
                                />
                                <FieldComponent
                                    name='designation'
                                    type='text'
                                    fieldType='input'
                                    label='Designation'
                                    handleChange={handleChange}
                                />
                                <FieldComponent
                                    name='description'
                                    type='textarea'
                                    fieldType='textarea'
                                    label='Description'
                                    handleChange={handleChange}
                                />
                                <FieldComponent
                                    name='featured_img'
                                    type='file'
                                    fieldType='input'
                                    label='Featured Image'
                                    handleChange={handleImageChange}
                                />
                                {
                                    addForm.featured_img ? <img src={addForm.featured_img} alt='' style={imgprev} /> : <></>
                                }
                                <FieldComponent
                                    name='status'
                                    type='select'
                                    fieldType='select'
                                    label='Status'
                                    handleChange={handleChange}
                                    values={Status}
                                />
                            </div>
                            <div className="card-footer">
                                <button type="submit" className="btn btn-primary" onClick={handleAddForm}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
} 