import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
const logWrap = {
    padding: "20px",
    textAlign: "-webkit-center",
    marginTop: "50px",
}

const outWrap = {
    textAlign: "-webkit-center",
}

export default function AddBlog() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    // this.uploadSingleFile = this.uploadSingleFile.bind(this)
    // this.upload = this.upload.bind(this)

    const [addForm, setAddForm] = useState({
        username: "",
        password: "",
    })

    const navigate = useNavigate();

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    function handleAddForm(e) {
        // console.log(addForm);return false;
        e.preventDefault();
        fetch(`https://nyatapol.com/backend/api/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8"
                // "Content-Type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
            },
            body: JSON.stringify(addForm),
        })
            .then(resp => resp.json())
            .then(data => {
                console.log(data.status);
                if (data.status == "SUCCESS") {
                    localStorage.setItem("isAuthenticated", data.user.logins_token);
                    window.location.pathname = "/admin/dashboard";
                } else {
                    alert(data.status_message);
                }
                // alert('success');
                // handleBlogUpdate(updatedBlog.data)
                // navigate('/admin/blog/blogs')
            })
    }

    // capture user input in edit form inputs
    function handleChange(e) {
        setAddForm({
            ...addForm,
            [e.target.name]: e.target.value
        })

    }


    return (
        <div className="Outwrap" style={outWrap}>
            <div className="login-wrapper" style={logWrap}>
                <h2>Sign In Page</h2>
                <form>
                    <label>
                        <p>Email</p>
                        <input type="text" name="email" onChange={(e) => handleChange(e)} />
                    </label>
                    <label>
                        <p>Password</p>
                        <input type="password" name="password" onChange={(e) => handleChange(e)} />
                    </label>
                    <div>
                        <button type="submit" onClick={handleAddForm}>Submit</button>
                    </div>
                </form>

                <Link to="/" >Home</Link>
            </div>
        </div>
    );
} 