import React from 'react'
let imgprev = {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    margin: "20px",
}
function Edit({ editForm, handleChange, handleItemUpdate, cancelEdit, handleImageChange }) {
    let { id, title, description, subtitle, featured_img, featured_img_data } = editForm

    // PATCH request; calls handleItemUpdate to push changes to the page
    function handleEditForm(e) {
        // console.log(editForm);return false;
        e.preventDefault();
        fetch(`https://nyatapol.com/backend/api/item/edit/${id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(editForm),
        })
            .then(resp => resp.json())
            .then(updatedItem => {
                console.log(updatedItem.data);
                handleItemUpdate(updatedItem.data)
            })
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card card-primary">
                        <div className="card-header">
                            <h3 className="card-title">Edit Blog</h3>
                        </div>
                        <div className="card-body">
                            <form id="quickForm">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label for="title">Title</label>
                                        <input type="text" name="title" className="form-control" id="title" placeholder="Enter Title" value={title} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="description">Description</label>
                                        {/* <input type="text" name="description" className="form-control" id="description" placeholder="Description" value={description} onChange={handleChange}/> */}
                                        <textarea
                                            name='description'
                                            className="form-control" id="description" placeholder="Description"
                                            onChange={handleChange}
                                        >{description}</textarea>
                                    </div>
                                    <div className="form-group">
                                        <label for="subtitle">Sub title</label>
                                        <input type="text" name="subtitle" className="form-control" id="subtitle" placeholder="Subtitle" value={subtitle} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="featured_img">Featured Image</label>
                                        <input type="file" name="featured_img" className="form-control" id="featured_img" placeholder="Featured Image" onChange={handleImageChange} />
                                        {
                                            featured_img ? <img src={featured_img} alt='' style={imgprev} /> : <></>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary" onClick={handleEditForm}>Submit</button>
                            <button className="btn btn-primary" onClick={cancelEdit}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Edit